import React, { Fragment, useState, useRef } from 'react';
import { Formik, Form, Field } from "formik";
import firebase from 'gatsby-plugin-firebase';
import NotificationSystem from 'react-notification-system';
import { StyledWrapper } from "../../../containers/ForgotPassword/ForgotPassword.styles";
import logo from '../../../components/images/logo.png';
import loading from '../../../components/images/loading.gif';


const ForgotPassword = () => {
  const [authError, setAuthError] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const notificationSystem = useRef();

  const addNotification = () => {

    const notification = notificationSystem.current;

    notification.addNotification({
      title: 'Reset Email Sent',
      message: 'Please check you mailbox to reset your password',
      level: 'success'
    });
  };

  const validateEmail = value => {
    let error;

    if (!value) {
      error = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  const resetMail = values => {
    let auth = firebase.auth();

    let actionCodeSettings = {
      url: 'https://www.devwares.com/app2/signin',
    };

    auth
      .sendPasswordResetEmail(values.email, actionCodeSettings)
      .then(function() {
        // Email sent.
        setButtonState(false);
        addNotification();
      })
      .catch((error) => {
        // An error happened.
        let errorMessage = error.message;

        setAuthError(errorMessage);

      });
   };

  return (
    <Fragment>
      <NotificationSystem ref={notificationSystem} />
      <StyledWrapper>
        <div className="box">
          <div className="logo">
            <img src={logo} alt="devwares" />
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={values => {
              setButtonState(true);
              resetMail(values);
            }}
          >
            {({
              errors,
              touched,
              handleChange,
              handleBlur,
              /* and other goodies */
            }) => (
              <Form className="form login">
                <div className="form-field">
                  <Field
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    validate={validateEmail}
                  />
                </div>
                <div className="red-text">{errors.email && touched.email && errors.email}</div>
                <div className="form-submit">
                  {buttonState && (
                    <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                  )}
                  {!buttonState && <input type="submit" disabled={buttonState} value="Submit" />}
                </div>
                <div className="red-text">{authError ? <p>{authError} </p> : null}</div>
              </Form>
            )}
          </Formik>
        </div>
      </StyledWrapper>
    </Fragment>
  );
};
export default ForgotPassword;